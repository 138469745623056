<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
        <card-general-informations :current-item="currentItem" :validation="error_validation"
          :validate="error_validate">
        </card-general-informations>

        <b-row class="match-height">
          <b-col md="6">
            <card-host :current-item="currentItem" :validation="error_validation" :validate="error_validate">
            </card-host>
          </b-col>
          <b-col md="6">
            <card-location :current-item="currentItem" :validation="error_validation" :validate="error_validate">
            </card-location>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <card-bookingtimes :current-item="currentItem" :validation="error_validation" :validate="error_validate">
            </card-bookingtimes>
          </b-col>
          <b-col md="6">
            <card-payment :current-item="currentItem" :validation="error_validation" :validate="error_validate">
            </card-payment>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <card-timeslots-fixed v-if="currentItem.booking_type ==1" :current-item="currentItem"
              :validation="error_validation" :validate="error_validate">
            </card-timeslots-fixed>
            <card-timeslots-dynamic v-if="currentItem.booking_type ==2" :current-item="currentItem"
              :validation="error_validation" :validate="error_validate">
            </card-timeslots-dynamic>
          </b-col>
          <b-col md="6">
            <card-cancellation-policy :current-item="currentItem" :validation="error_validation"
              :validate="error_validate">
            </card-cancellation-policy>
          </b-col>
        </b-row>

        <b-card>
          <b-row>
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
                {{ $t('Create event') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BRow,
  BToast,
  BCard,
} from 'bootstrap-vue'

import {ValidationObserver, localize, localeChanged} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import CardGeneralInformations from "@/views/events/EventCards/CardGeneralInformations";
import CardHost from "@/views/events/EventCards/CardHost";
import CardLocation from "@/views/events/EventCards/CardLocation";
import CardBookingtimes from "@/views/events/EventCards/CardBookingtimes";
import CardPayment from "@/views/events/EventCards/CardPayment";
import CardTimeslotsFixed from "@/views/events/EventCards/CardTimeslotsFixed";
import CardTimeslotsDynamic from "@/views/events/EventCards/CardTimeslotsDynamic";
import CardCancellationPolicy from "@/views/events/EventCards/CardCancellationPolicy";

export default {
  components: {
    BForm,
    BButton,
    BRow,
    BCol,
    BCard,
    // Form Validation
    ValidationObserver,

    CardGeneralInformations,
    CardHost,
    CardLocation,
    CardBookingtimes,
    CardPayment,
    CardTimeslotsDynamic,
    CardTimeslotsFixed,
    CardCancellationPolicy
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        time_slots_count: 1,
        booking_type: 1,
        payment_type: 1,
        payment_methods: 1,
        event_type_form: 1,
        informations: '',
        status: '1',
        eventlocation: 1,
        language: 'de',
        nextTodoId: 2,
        paymentMethods: 1,
        free_event: 0,
        cancellation_conditions: [],
        timeslots: [],
        timeslots_dynamic: [],
        has_bookings: false,
      },
      error_validation: {},
      error_validate: false
    }
  },
  methods: {
    onSubmit() {
      let url = `/events`
      // this.currentItem.timeslots = this.dateData
      // this.currentItem.cancellation_condition = this.cancellationData
      this.$http.post(url, this.currentItem)
          .then(response => {
            this.$router.push(
                {
                  name: 'apps-events-list',
                },
                () => setTimeout(() => {
                  this.$bvToast.toast(this.$i18n.t('Event successfully added'), {
                    title: this.$i18n.t('Success'),
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right'
                  })
                })
            )
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.validation = error.response.data.errors;
              this.validate = true
            } else {
              this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                title: this.$i18n.t(error.response.data.message),
                variant: "danger",
                toaster: "b-toaster-bottom-right",
              });
            }
          })
    },

    switchLoc () {

      // this.$i18n.locale = this.localization;
      // localeChanged();
      this.$refs.refFormObserver.validate();
    }

  },
  computed: {
    localization() {
      return this.$i18n.locale;
    }

  }, watch: {
    localization() {
      localeChanged();
      setTimeout(()=>{
        this.$refs.refFormObserver.validate();
      },200)
    }
  }
}
</script>

<style>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.visible-row {
  overflow: visible !important;
}


</style>

